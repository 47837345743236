<template>
  <div
    class="EnrollmentView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t(
        'flows.student.steps.enrollment.title',
        { university: session?.university?.name ?? '' },
      )"
      :stepper-steps="stepperSteps"
      :active-stepper-step-id="currentStep.id"
    />

    <div class="container">
      <div
        class="__label ">
        {{ $t('flows.student.steps.enrollment.studyProgramLabel') }}
      </div>
      <InputSelect
        v-model="session.program.value"
        :is-invalid="hasSubmitted && !session.program.isValidClientSide"
        :is-placeholder-persistent="true"
        :is-searchable="true"
        :options="$i18n.messages[$i18n.locale].studentPrograms"
        :placeholder="session.program.placeholder"
        :search-placeholder="$t('shared.search')"
      />

      <div
        class="__label mt-3">
        {{ $t('flows.student.steps.enrollment.graduationLabel') }}
      </div>
      <div
        class="__double">
        <InputSelect
          class="__field"
          v-model="session.graduationMonth.value"
          :placeholder="session.graduationMonth.placeholder"
          :is-placeholder-persistent="true"
          :options="$i18n.messages[$i18n.locale].months"
          :is-invalid="hasSubmitted && !session.graduationMonth.isValidClientSide"
        />

        <span
          class="__spacer">
        </span>

        <InputSelect
          class="__field"
          v-model="session.graduationYear.value"
          :placeholder="session.graduationYear.placeholder"
          :is-placeholder-persistent="true"
          :options="computedYearOptions"
          :is-invalid="hasSubmitted && !session.graduationYear.isValidClientSide"
        />
      </div>

      <ErrorTile
        class="mt-2"
        v-show="hasSubmitted && !isCurrentStepValidClientSide">
        {{ $t('shared.pleaseCompleteFields') }}
      </ErrorTile>

      <div
        class="text-center mt-4 pb-6">
        <ButtonBase
          class="uppercase"
          type="submit"
          :is-loading="isSubmitting"
          @click="handleSubmit">
          {{ computedCtaText }}
        </ButtonBase>
      </div>
    </div>
  </div>
</template>


<script>
import ButtonBase from '@/components/ButtonBase.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import HeaderStep from '@/components/HeaderStep.vue';
import InputSelect from '@/components/InputSelect.vue';

export default {
  name: 'EnrollmentView',

  components: {
    ButtonBase,
    ErrorTile,
    HeaderStep,
    InputSelect,
  },

  inject: [
    'domo',
    'flow',
    'session',
    'nextStep',
    'currentStep',
    'stepperSteps',
    'isCurrentStepValidClientSide',
  ],

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
    };
  },

  computed: {
    computedYearOptions() {
      const currentYear = new Date().getFullYear();

      return [...Array(10)].map((_, i) => ({
        label: `${currentYear + i}`,
        value: `${currentYear + i}`,
      }));
    },

    computedCtaText() {
      return !this.nextStep
        ? this.$t('ctas.finishAndLaunchDomo')
        : this.$t('ctas.next');
    },
  },

  methods: {
    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.isCurrentStepValidClientSide) return;

      this.isSubmitting = true;

      await this.currentStep.submitHandler({
        domo: this.domo,
        session: this.session,
        currentStep: this.currentStep,
      });

      if (!this.nextStep) return;

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: this.nextStep.routeName,
        },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables';

.__label {
  font-size: 16px;
  font-weight: $font-weight-semi-bold;
  padding-bottom: 8px;
}

.__double {
  display: flex;
}

.__spacer {
  display: block;
  padding: 8px;
}

.__field {
  flex: 1 1 50%;
}
</style>
